.edit-step-risks__risk {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}


.edit-step-risks__risk .risk-input {
  flex: 1;
}

.edit-step-risks__save-button {
  display: flex;
  justify-content: flex-end;
}