.process-builder__container {
  display: flex;
  padding: 16px;
  height: calc(100% - 23px);
}

.process-builder__side-bar {
  min-width: 300px;
}

.process-builder__map {
  flex: 1;
  margin-left: 16px;
}

.process-builder__save-container {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.process-builder__save-warning {
  color: var(--error);
  margin-right: 8px;
}