.process-side-bar {
  background-color: white;
  max-width: 400px;
  height: calc(100vh - 80px);
  padding: 16px;
  margin-left: 16px;  
  border-radius: 16px;
  overflow-y: scroll;
}

.process-side-bar__title {
  margin: 0;
}