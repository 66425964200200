.data-mapping-input__container {
  display: grid;
  grid-template-columns: 1fr 20px 2fr 32px;
  align-items: center;
  gap: 8px;
}

.data-mapping-input__field-container {
  display: flex;
  gap: 8px;
  align-items: center;
}