.integration-form__header-container {
  display: flex;
  align-items: center;
}

.integration-form__sub-header {
  margin-left: 16px;
}

.integration-form__inner-container {
  display: flex;
}

.integration-form__left {
  width: 40%;
  margin-right: 32px;
}

.integration-form__right {
  flex-grow: 1;
}

.integration-form__docs {
  overflow-x: scroll;
  background-color: var(--very-light-background);
  padding: 8px;
  margin-bottom: 16px;
}