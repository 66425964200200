.step-node {
  width: 150px;
  min-height: 175px;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  padding-bottom: 24px;
  font-size: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  border: 1px solid var(--primary);
  position: relative;
}

.step-node--selected {
  border: 2px solid var(--accent)
}

.step-node--completed {
  border: 2px solid rgb(139, 169, 139);
}

.step-node__owners {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.step-node__owner {
  background-color: var(--very-light-background);
  word-wrap: break-word;
  margin-bottom: 4px;
  padding: 4px 8px;
  border-radius: 8px;
}

.step-select-button {
  position: absolute;
  background-color: white;
  z-index: 1;
}

.step-select-button--selected {
  border: 2px solid var(--primary);
}

.step-node__icon {
  width: 20px;
  height: 20px;
  margin: 0;
  display: block;
  stroke: var(--quaternary);
}

.step-node__icon--agent {
  stroke: var(--accent);
}

.step-node__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.step-node__attributes {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 4px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.step-node__attributes--decision {
  left: 31%;
  bottom: 1px;
  max-width: 66px;
}

.step-node__attributes svg {
  width: 16px;
  height: 16px;
}

.step-node__rag-container {
position: absolute;
  bottom: -20px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.step-node__rag-container--decision {
  bottom: -20%;
  left: 68%;
  transform: rotate(315deg);
}

.step-node__rag-arrow {
  width: 10px;
  height: 10px;
}

.step-node__rag {
  
  width: 12px;
  height: 12px;
  border-radius: 8px;
}

.step-node__rag--red {
  background-color: var(--error);
}

.step-node__rag--amber {
  background-color: var(--warning);
}

.step-node__rag--green {
  background-color: var(--success);
}
