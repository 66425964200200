.file-list {
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.file-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.file-list__item {
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid var(--gray-100);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.file-list__item-title {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  width: 100%
}
