.process-admin__csv-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.process-admin__selected-step {
  padding-top: 32px;
  padding-bottom: 32px;
  width: 50%;
  min-width: 300px;
  margin: auto;
}

.process-admin__preview {
  min-height: 500px;
  margin-top: 32px;
}