.main-profile-holder {
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.settings-icon {
    font-size: medium;
}

.owner-groups-header {
    margin-top: 50px;
    font-size: medium;
}

.administation-settings {
    width: 40%;
    font-weight: bold;
    margin-left: 20px;
}

.edit-background_icon-button {
    font-size: small;
    font-weight: light;
    display: flex;
    align-items: center;
    justify-content:left
}


.profile-container {
    display: flex;
}