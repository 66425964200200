.supervisor-page-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.supervisor-content-container {
  display: flex;
  gap: 1rem;
}

.filters-container {
  min-width: 300px;
  max-width: 300px;
}

.tabs-container {
  flex: 1;
}

/* Override react-tabs default styles for better spacing */
.react-tabs {
  width: 100%;
}

.react-tabs__tab-panel {
  padding-top: 1rem;
}

/* Ensure tables and calendar take full width of container */
.tabs-container .box {
  width: 100%;
} 