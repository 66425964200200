.due-date {
  display: flex;
  align-items: center;
  gap: 4px;
}

.due-date svg {
  width: 24px;
  margin-right: 8px;
  stroke: currentColor;
}

.due-date__date {
  display: flex;
  align-items: center;
  border-right: 1px solid var(--gray-200);
  padding-right: 8px;
  width: 85px;
}

.due-date__time {
  display: flex;
  align-items: center;
  width: 85px;
}

.due-date--green {
  color: var(--success);
}

.due-date--amber {
  color: var(--warning);
}

.due-date--red {
  color: var(--error);
}