.home-page__search h1 {
  margin: 0;
}

.home-page__search p {
  margin: 0;
}

.home-page__recent-cases {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--gray-300);
}

.home-page__recent-cases h3 {
  margin: 0;
}

.home-page__case-link {
  text-decoration: none;
  color: var(--blue) !important;
}

.home-page__recent-cases-container {
  max-height: 100%;
  overflow-y: scroll;
}

.home-page__recent-case {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;
}

.home-page__recent-case:hover {
  background-color: var(--light-background);
}

.home-page__recent-case-process, .home-page__recent-case-code {
  font-size: 12px;
  color: var(--gray-500);
}

.home-page__recent-case-title {
  font-size: 16px;
  font-weight: 600;
}

.home-page__recent-case-status-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.home-page__recent-case-status-container svg {
  width: 18px;
  height: 18px;
}

@media (min-width: 900px) {
  .home-page__upper-container {
    flex-direction: row;
  }

  .home-page__new-case {
    width: calc(50% - 8px);
  }
 
  .home-page__content-container {
    display: flex;
    gap: 16px;
    flex: 1;
  }

  .home-page__case-list {
    flex: 2;
  }

  .home-page__new-case {
    flex: 1;
  }
}