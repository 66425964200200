.configure-automation__container {
  max-width: 1200px;
  margin: 0 auto;
}

.configure-automation__letter-container {
  display: flex;
}

.configure-automation__letter-fields {
  width: 60%;
}

.configure-automation__letter-info {
  width: calc(50% - 32px);
  margin-top: -144px;
  margin-left: 32px;
  padding: 0 16px;
  padding-bottom: 16px;
  background-color: var(--very-light-background);
  border-radius: 4px;
}

.item-field-config__bottom-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.item-field-config__required-checkbox {
  margin-bottom: 0;
}