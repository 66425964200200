.risk-input {
  display: flex;
  gap: 8px;
}

.risk-input__left {
  flex: 1;
}

.risk-input__middle {
  flex: 1;
}

.risk-input__right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 200px;
}
