.edit-edge__label {
  display: block;
  margin-bottom: 4px;
}

.edit-edge__edit-label {
  margin-top: 16px;
  margin-bottom: 16px; 
}

.edit-edge__delete-button {
  color: var(--error);
  font-weight: bold;
  margin: auto;
  margin-top: 32px;
}

.edit-edge__delete-button:hover {
  color: white;
  background-color: var(--error);
}