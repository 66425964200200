.item-field-config {
  padding: 16px;
  margin: 16px 0;
  border: 1px solid var(--very-light-background);
  border-radius: 8px;
}
.item-field-config__list {
  list-style-type: none;
  padding: 0;
}

.item-field-config__item {
  padding: 8px;
  margin-bottom: 4px;
  background-color: var(--very-light-background);
}

.item-field-config__title {
  margin: 0;
  font-weight: bold;
  margin-bottom: 8px;
}

.item-field-config__inputs {
  display: flex;
}

.item-field-config__name-input {
  flex-grow: 2;
  margin-right: 8px;
  width: 70%;
}

.item-field-config__type-input {
  flex-grow: 1;
  width: 30%;
  padding-bottom: 4px;;
}
