
.spinner_b2T7 {
    animation: spinner_xe7Q 0.8s linear infinite;
  }
  
  .spinner_YRVV {
    animation-delay: -0.65s;
  }
  .spinner_c9oY {
    animation-delay: -0.5s;
  }
  
  @keyframes spinner_xe7Q {
    93.75%,
    100% {
      r: 3px;
    }
    46.875% {
      r: 0.2px;
    }
  }