.edit-process__container {
  display: flex;
}

.edit-process__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-process__box-left {
  width: 60%;
}

.edit-process__box-right {
  width: calc(40% - 16px);
  margin-left: 16px;
}

.edit-process__publish-container {
  margin-bottom: 32px;
  padding: 8px;
  border-radius: 4px;
}

.edit-process__publish-header {
  margin: 0;
}

.edit-process__publish-text {
  margin-top: 4px;
  margin-bottom: 4px;
}

.edit-process__publish-container--unpublished {
  background-color: var(--warning);
}