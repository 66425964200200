.case-step__type {
  margin: 0;
  color: var(--secondary-text);
}

.case-step__name {
  margin: 0;
  margin-bottom: 8px;
  font-size: 32px;
}

.case-step__container {
  display: flex;
  min-height: 500px;
  gap: 16px;
}

.case-step__main-container {
  height: 100%;
  min-height: 500px;
}

.case-step__meta {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 8px;
}

.case-step__owners {
  margin-bottom: 16px;
}

.case-step__next-step-container {
  display: none;
}

@media (min-width: 1250px) {
  .case-step__main-container {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .case-step-completion__container {
    flex: 1;
  }

  .case-step__next-step-container {
    flex: 1;
    display: inline-block;
    border-radius: 8px;
    min-width: 280px;
  }
}