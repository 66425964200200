.decision-node {
  transform:rotate(45deg);
  width: 150px;
  height: 150px;
  border: 1px solid var(--border);
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  border: 1px solid var(--primary);
}

.decision-node--current {
  border: 2px solid var(--accent);
}

.decision-node__text {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 150px;
  height: 150px;
  font-size: 12px;
}