.note-list {
  height: 60vh;
  max-height: 700px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.note-list::-webkit-scrollbar {
  display: none;
}

.note-list__item {
  border: 1px solid var(--gray-100);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 18px;
}

.note-list__user-chip {
  margin-bottom: 8px;
  padding: 8px;
  border-bottom: 1px solid var(--gray-400);
}

.note-list__text {
  font-size: 14px;
  word-break: break-all;
  margin: 0;
  margin-bottom: 4px;
  background-color: var(--gray-100);
  padding: 8px;
  border-radius: 4px;
}

.note-list__meta {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
}

.note-list__meta__icon {
  color: var(--gray-500);
}
.note-list__meta__date {
  margin: 0;
  font-size: 12px;
  color: var(--gray-500);
}

