.next-steps__container {
  height: 100%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--very-light-background);
  border: 1px solid var(--border);
  border-radius: 8px;
  position: relative;
  padding: 16px;
  max-width: 400px;
}

.next-steps__title {
  position: absolute;
  top: 16px;
  left: 16px;
  font-weight: bold;
  margin: 0;
}

.next-steps__step {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: -16px;
  width: 100%;
}

.next-steps__previous-step-label {
  display: flex;
  align-items: center;
}

.next-steps__previous-step-label span {
  padding: 8px;
  background-color: white;
  border-radius: 4px;
}

.next-steps__previous-step-label svg {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  margin-right: 8px;
  
}