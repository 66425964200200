@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --primary: #3E63DD;
  --secondary: #85abd3;
  --success: #52A43A;
  --tertiary: #32318A;
  --quaternary: #171D28;
  --error: #E5484D;
  --warning: #F3BA48;
  --accent: #D3488E;

  --light-background: #F3F4F6;
  --warning-background: #ffebc2;
  --accent-background: #ffecf6;
  --very-light-background: #f2f4f8ff;
  --border: #bec8daff;
  --secondary-text: #3c4b68ff;

    /* Gray */
    --gray-100: #F3F4F6;
    --gray-200: #F3F4F6;
    --gray-300: #D1D5DB;
    --gray-400: #AFB6BF;
    --gray-500: #6B7280;
    --black: #171D29;
  
    /* Main */
    --blue: #3E63DD;
    --blue-light: #E2E7FA;
    --blue-100: #EFF2FC;
  
    /* Green */
    --green: #30A46C;
    --green-light: #E6F4ED;
  
    /* Yellow */
    --yellow-dark: #BC910C;
    --yellow: #FFC100;
    --yellow-light: #FFF4D1;
  
    /* Red */
    --red: #E5484D;
    --red-light: #FCE7E8;
  
    /* Grass */
    --grass: #7CC000;
    --grass-light: #E7F4D1;
  
    /* Pink */
    --pink: #E93D82;
    --pink-light: #FCE8F0;
  
    /* Orange */
    --orange: #F68B15;
    --orange-light: #FEEEf5;
  
    /* Violet */
    --violet: #6E56CF;
    --violet-light: #E9E6F8;
  
    /* Sky blue */
    --sky-blue: #00A2E8;
    --sky-blue-light: #D9F1FC;
  
    /* Magenta */
    --magenta: #8E4EC6;
    --magenta-light: #EEE4F6;
}

* {
  box-sizing: border-box;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

*::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.radix-themes {
  background-color: transparent !important;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-background);
}

svg {
  width: 24px;
}

.icon-img {
  width: 24px;
}

p, span, textarea, input, li, label, small, a, option, select {
  color: var(--text);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: var(--very-light-background);
  display: inline-block;
  padding: 16px;
  width: 100%;
}

.rt-SwitchRoot:where(.rt-variant-surface)::before {
  background-image: linear-gradient(to right, var(--blue) 40%, transparent 60%) !important;
}

h1, h2, h3, h4, th {
  font-family: 'Inter', sans-serif;
  color: var(--text);
  margin: 0;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 600;
}

h3 {
  font-weight: 600;
}

h4 {
  font-weight: 700;
}

button {
  font-family: 'Inter', sans-serif;
}
button {
  cursor: pointer;
  background: none;
  border: 1px solid var(--border);
  border-radius: 3px;
}

.content {
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 0;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 1100px;
}

.content--wide {
  max-width: 1800px;
  padding: 0;
}

.button {
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: var(--primary);
  font-size: 18px;
  padding: 8px 32px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid var(--primary);
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out, border-color 150ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: max-content;
}

.button--full-width {
  width: 100%;
}

.button svg {
  width: 18px;
  margin-right: 2px;
}

.button:hover, .button:focus {
  color: var(--primary);
  background-color: white;
}

.button:disabled {
  pointer-events: none;
  background-color: var(--gray-300);
  border-color: var(--gray-300);
  color: white;
}

.button--small {
  font-size: 14px;
  padding: 4px 16px;
}

.button--small svg {
  width: 16px;
}

.button--secondary {
  color: var(--primary);
  background-color: white;
}

.button--secondary:focus, .button--secondary:hover {
  color: white;
  background-color: var(--primary);
}

.button--yellow {
  background-color: var(--yellow);
  border-color: var(--yellow);
  color: var(--black);
}

.button__group {
  display: flex;
  align-items: center;
}

.button__group button {
  margin-right: 16px;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: var(--text);
  transition: color 300ms ease-in-out;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.link-button svg {
  width: 18px
}

.link-button:hover {
  color: var(--primary)
}

.link-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.box-button {
  width: 100px;
  height:  100px;
  margin: 4px;
  border-radius: 4px;
  background: none;
  border: 2px solid var(--border);
  transition: border-color 300ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px
}

.box-button:hover {
  border-color: var(--accent);
}

.box-button span {
  display: inline-block;
}

.box-button__container {
  display: flex;
  flex-wrap: wrap;
}

.page__inner {
  background-color: white;
  border-radius: 12px;
  min-height: 600px;
  padding: 16px;
}

.page__inner + .page__inner {
  margin-top: 64px;
}

.page__label {
  margin: 0;
}

.page__title {
  margin-top: 0;
}

.loading__full-page {
  max-width: 200px;
  margin: auto;
  display: block;
  margin-top: 15vh;
}

.info-text {
  color: var(--secondary-text);
  font-size: 14px;
  margin: 0;
}

.icon-button {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
}

.icon-button--grey {
  background-color: var(--gray-100);
  color: var(--gray-500);
  border-radius: 8px;
  padding: 4px;
}

.box {
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
}

.react-tabs__tab-list {
  border: none !important;
  background-color: var(--very-light-background) !important;
  padding: 8px !important;
  width: max-content !important;
  border-radius: 8px !important;
}

.react-tabs__tab {
  font-family: 'Inter', sans-serif;
}

.react-tabs__tab:focus:after {
  display: none !important;
}

.react-tabs__tab--selected {
  text-decoration: none !important;
  border: none !important;
  background-color: white!important;
  border-radius: 4px !important;
}

.react-tabs__tab-panel--selected {
  padding: 12px;
}

.mdxeditor-popup-container {
  border: transparent !important;
}