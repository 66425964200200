.file-upload__input {
  display: none;
}

.file-upload__label {
  display: block;
  width: 100%;
  height: 125px;
  background-color: var(--blue-100);
  padding: 16px;
  text-align: center;
  border-radius: 8px;
  border: 1px dashed var(--blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.file-upload__label:hover {
  background-color: var(--blue-light);
}

.file-upload__label:hover .file-upload__icon {
  stroke: var(--blue-light);
}

.file-upload__icon {
  fill: var(--blue);
  stroke: var(--blue-100);
  width: 48px;
  height: 48px;
}

.file-upload__button {
  width: 100%;
  margin-top: 16px;
}