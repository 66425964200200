.login__container {
  /* margin-top: 32px; */
  border-radius: 16px;
  padding: 32px;
  max-width: 600px;
  margin: auto;
}

.login__img {
  display: block;
  width: 250px;
  border-radius: 16px;
  margin: auto;
  margin-bottom: 16px;
}

.login__inner {
  padding: 32px;
}

.login__title {
  margin: 0;
  margin-bottom: 16px;
}

.login__help {
  display: block;
  text-align: center;
  color: var(--secondary-text);
}