.user-chip {
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.user-chip--compact {
  padding: 16px 4px;
}

.user-chip--small {
  padding: 0;
}

.user-chip__info {
  display: flex;
  align-items: center;
}

.user-chip__name {
  margin: 0;
  font-weight: medium;
  font-size: 14px;
}

.user-chip__initials {
  background-color: var(--blue-light);
  color: var(--blue);
  border-radius: 50%;
  margin-right: 8px;
  font-weight: medium;
  width: 32px;
  height: 32px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-chip--small .user-chip__initials {
  padding: 8px;
  font-size: 12px;
}

.user-chip__email {
  margin: 0;
  font-size: 12px;
  color: var(--secondary-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.user-chip--small .user-chip__email {
  font-size: 10px;
}

.user-chip__delete-button {
  background-color: var(--gray-100);
  padding: 8px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.user-chip__delete-button svg {
  width: 18px;
  height: 18px;
}