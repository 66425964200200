.item-field__file {
  margin-bottom: 16px;;
}

.item-field__view-only {
  /* display: flex; */
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.item-field__view-only-label {
  font-weight: 500;
  font-size: 14px;
}

.item-field__view-only-value {
  background-color: var(--blue-100);
  color: var(--blue);
  padding: 4px 8px;
  border-radius: 4px;
  width: fit-content;
}

.item-field__compact .item-field__view-only-label, .item-field__view-only-value {
  font-size: 12px;
  font-weight: 400;
}

.item-field__compact .item-field__view-only {
  margin-bottom: 4px;
}