.configure-step-agent__header {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.configure-step-agent__tools-container {
  display: flex;
  gap: 32px;
}

.configure-step-agent__internal-tools,
.configure-step-agent__external-tools {
  flex: 1;
  background: #f8f9fa;
  padding: 24px;
  border-radius: 8px;
}

.configure-step-agent__tools h4 {
  margin: 0 0 16px 0;
  font-size: 18px;
  color: #2d3748;
}

.configure-step-agent__tools-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.configure-step-agent__tool-item {
  background: white;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.configure-step-agent__tool-item h5 {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #2d3748;
}

.configure-step-agent__tool-item p {
  margin-bottom: 16px;
  font-size: 14px;
  color: #4a5568;
}

.configure-step-agent__tool-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.configure-step-agent__tool-header h5 {
  margin: 0;
} 