.search__form {
  margin-bottom: 32px;
}



@media (min-width: 800px) {
  .search__input-container {
    display: flex;
    align-items: center;
  }
  
  .search__select-container {
    width: 40%;
    margin-right: 16px;
  }
  
}