.progress {
  appearance: none;
  height: 5px;
  border-radius: 100px;
  width: 100%;
  
  
}

/* Chrome and Safari */
.progress::-webkit-progress-bar {
  background: var(--light-background);
  border-radius: 100px;
}

/* Firefox */
.progress::-moz-progress-bar {
  background-color: var(--primary);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.progress::-webkit-progress-value {
  background-color: var(--primary);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.progress::-webkit-progress-value {
  transition: width 1s;
}

/* Green variant */
.progress--green::-webkit-progress-value {
  background-color: var(--green);
}

.progress--green::-moz-progress-bar {
  background-color: var(--green);
}

/* Default variant - explicitly set for clarity */
.progress--default::-webkit-progress-value {
  background-color: var(--primary);
}

.progress--default::-moz-progress-bar {
  background-color: var(--primary);
}