.stat-box__value {
  font-family: sans-serif;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin-bottom: 8px;
}

.stat-box__label {
  display: block;
  text-align: center;
  margin: 0;
}

.stat-box__sub-label {
  display: block;
  text-align: center;
  margin: 0;
  margin-top: 4px;
  font-size: 12px;
  color: #666;
}
