.tag-select__container {
  margin-top: 16px;
  margin-bottom: 16px;
}

.tag-select__label {
  margin: 0;
  font-weight: bold;
}

.tag-select__selected-tags {
  display: flex;
  flex-wrap: wrap;
}

.tag-select__selected-tag {
  display: flex;
  align-items: center;
  margin: 4px;
  background-color: var(--very-light-background);
  padding: 4px;
  border-radius: 2px;
}