.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: -1;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  overflow: hidden;
}

.modal-content {
  position: fixed;
  top: 5vh;

  left: 50%;
  width: 90vw;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: scroll;
  min-height: 200px;
  background-color: white;
  transform: translateX(10%);
  padding: 16px;
  border-radius: 8px;
  transition: opacity 300ms ease-in-out;
  z-index: -1;
  opacity: 0;
  overflow: scroll;
  transform: translateX(-50%);
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-overlay--show {
  z-index: 2;
  opacity: 1;
}

.modal-content--show {
  z-index: 3;
  opacity: 1;
}

.close-button {
  margin-left: auto;
  display: block;
  margin-bottom: 16px;
}