.process-step-form__previous-step-input {
  border: 1px solid var(--very-light-background);
  margin-bottom: 32px;
  margin-top: 32px;
  padding: 16px;
}

.process-step-form__previous-step-label {
  margin: 0;
  margin-bottom: 8px;
  font-weight: bold;
}

.process-step-form__previous-step-label .input__label {
  font-size: 14px;
}

.process-step-form__previous-step {
  background-color: var(--very-light-background);
  padding: 8px;
  margin: 4px;
  margin-bottom: 8px;
  border-radius: 8px;
  display: inline-block;
}

.process-step-form__actions {
  display: flex;
}

.process-step-form__actions .button--secondary {
  margin-left: 16px;
}