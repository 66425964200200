.toast {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    
    min-height: 100px;
    transition: all 150ms ease-in-out 150ms;
    transform: translate(150%, 0);

    background-color: white;
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 1px solid var(--gray-300);
}

.toast--show {
    transform: translate(0, 0);
    z-index: 5;
}

.toast__inner {
    display: flex;
    align-items: center;
}

/* bottom-toast end */

.toast__text {
    margin-left: 16px;
}

.toast__header {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.toast__message {
    margin: 0;
    font-size: 14px;
}

.toast--error {
    background-color: var(--error);
    border-color: var(--error);
    color: white;
}

.toast--success {
    background-color: var(--success);
    border-color: var(--success);
    color: white;
}

.toast__spinner {
    width: 48px;
}

.toast__icon {
    width: 32px;
}

.toast--info .toast__icon {
    color: var(--primary);
}

@media print {
    .toast {
        display: none;
    }
}