.configure-automated-lookup {
  display: flex;
  gap: 16px;
}

.configure-automated-lookup__form {
  flex: 1;
}

.configure-automated-lookup__demo-data {
  flex: 1;
  background-color: var(--light-background);
  padding: 16px;
  border-radius: 8px;
}