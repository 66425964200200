.attached-files {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px;
}

.file-item {
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 13px;
  max-width: 250px;
  min-width: 150px;
}

.file-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.file-icon.pdf {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23e74c3c'%3E%3Cpath d='M20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM10 9H18V11H10V9ZM10 12H14V14H10V12ZM10 6H18V8H10V6Z'/%3E%3C/svg%3E");
}

.file-icon.docx {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232b579a'%3E%3Cpath d='M21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5ZM19 13H13V19H19V13ZM11 13H5V19H11V13ZM19 5H13V11H19V5ZM11 5H5V11H11V5Z'/%3E%3C/svg%3E");
}

.file-name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
  color: #333;
  display: inline-block;
  max-width: calc(100% - 40px); /* Allow space for icon and remove button */
}

.remove-file {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 0 4px;
  line-height: 1;
  transition: color 0.2s;
  flex-shrink: 0;
}

.remove-file:hover {
  color: #d33;
} 