.toasts {
    position: fixed;
    right: 16px;
    bottom: 32px;
    width: 75%;
    max-width: 250px;
    /* min-width: 300px; */
    z-index: 15;
    transition: all 300ms ease-in-out;
}

@media (min-width: 750px) {
    .toasts {
        max-width: 400px;
    }
}