.input__container {
  margin-bottom: 16px;
  width: 100%;
}

.input__container--checkbox {
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  gap: 8px;
}

.input__container--read-only {
  flex-direction: row;
  width: 100%;
}

.input__container--small {
  margin-bottom: 8px;
}

.input {
  display: block;
  width: 100%;
  /* max-width: 400px; */
  padding: 8px;
  font-size: 16px;
  border: 1px solid var(--border);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.input:disabled {
  background-color: var(--very-light-background);
  cursor: not-allowed;
}

.input[type='checkbox'] {
  width: auto;
  margin-right: 8px;
}

.input__container--small > .input {
  padding: 4px;
  font-size: 14px;
}

textarea {
  min-height: 100px;
}

.input__label {
  display: block;
  margin-bottom: 4px;
}

.input__container--small > .input__label {
  font-weight: normal;
}

.input__help-text {
  display: block;
  margin-top: 2px;
  font-size: 12px;
  color: var(--secondary-text);
}

.input--readonly {
  border-color: transparent;
  margin: 0;
}

.input__container--invalid > .input {
  color: var(--error);
  border-color: var(--error);
  border-width: 2px;
}

.input__container--invalid > .input__label {
  color: var(--error);
}