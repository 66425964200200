.control-step {
  width: 175px;
  min-height: 175px;
  background-color: white;
  padding-top: 18px;
  padding-bottom: 24px;
  font-size: 12px;
  position: relative;
  box-sizing: border-box;
  /* Octagon shape */
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  /* Add extra padding to account for the octagon shape */
  padding-left: 32px;
  padding-right: 32px;
  /* Position relative for the pseudo-elements */
  position: relative;
  z-index: 1;
  /* Remove the filter drop-shadow */
  filter: none;
}

/* Create a background pseudo-element with box-shadow */
.control-step::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary);
  z-index: -2;
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
}

/* Create a white inner pseudo-element to create border effect */
.control-step::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background: white;
  z-index: -1;
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}

.control-step--selected::before {
  background: var(--accent);
}

.control-step--selected::after {
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.control-step--completed::before {
  background: rgb(139, 169, 139);
}

.control-step--completed::after {
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.control-step__text {
  display: block;
  text-align: center;
  margin-top: 8px;
}

.control-step__owners {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.control-step__owner {
  background-color: var(--very-light-background);
  word-wrap: break-word;
  margin-bottom: 4px;
  padding: 4px 8px;
  border-radius: 8px;
}

.control-step__icon {
  width: 20px;
  height: 20px;
  margin: 0;
  display: block;
  /* stroke: var(--quaternary); */
}

.control-step__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.control-step__attributes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 4px;
  position: absolute;
  bottom: 12px;
  left: 48px;
}

.control-step__attributes svg {
  width: 16px;
  height: 16px;
}

.control-step__rag-container {
  position: absolute;
  bottom: -20px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.control-step__rag-arrow {
  width: 10px;
  height: 10px;
}

.control-step__rag {
  width: 12px;
  height: 12px;
  border-radius: 8px;
}

.control-step__rag--red {
  background-color: var(--error);
}

.control-step__rag--amber {
  background-color: var(--warning);
}

.control-step__rag--green {
  background-color: var(--success);
} 