.tag-input__container {
  margin-top: 16px;
  margin-bottom: 16px;
}

.tag-input__label {
  font-weight: bold;
  margin: 0;
}

.tag-input__tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
}

.tag-input__tag {
  padding: 4px;
  margin: 4px;
  background-color: var(--very-light-background);
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.tag-input__tag button {
  margin-left: 4px;
}

.tag-input__input-container {
  display: flex;
  align-items: center;
  max-width: 400px;
}

.tag-input__button {
  margin-top: -16px;
  margin-left: 16px;
}

.tag-input__empty-message {
  padding: 4px;
  margin: 4px;
  font-size: 14px;
  color: var(--secondary-text);
}