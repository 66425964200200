.agent-chat {
    padding: 16px;
    border-radius: 8px;
    background-color: var(--yellow-light);
    height: 100%;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.agent-chat::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.agent-chat__header {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    flex: 1;
    padding-right: 8px;
    margin-right: -8px;
    position: relative;
}

.chat-message {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    line-height: 1.4;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.chat-message--system {
    align-self: flex-start;
}

.chat-message--user {
    align-self: flex-start;
    margin-left: auto;
    
}

.chat-message__content {
    margin-bottom: 4px;
}

.chat-message__content p {
    margin: 0;
}

.chat-message__content ul,
.chat-message__content ol {
    margin: 8px 0;
    padding-left: 16px;
}

.chat-message__content code {
    background-color: var(--gray-100);
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
}

.chat-message__content pre {
    background-color: var(--gray-100);
    padding: 12px;
    border-radius: 4px;
    overflow-x: auto;
}

.chat-message__content pre code {
    background-color: transparent;
    padding: 0;
}

.chat-message__content a {
    color: var(--blue-600);
    text-decoration: none;
}

.chat-message__content a:hover {
    text-decoration: underline;
}

.chat-message__tool-usage {
    margin: 8px 0;
    padding-top: 8px;
    border-top: 1px solid var(--gray-200);
}

.chat-message__tool-usage-title {
    font-size: 12px;
    color: var(--gray-600);
    margin-bottom: 4px;
    font-weight: 500;
}

.chat-message__tool-item {
    margin-bottom: 8px;
}

.chat-message__tool-item:last-child {
    margin-bottom: 0;
}

.chat-message__tool-name {
    font-size: 12px;
    color: var(--gray-700);
    font-weight: 500;
    margin-bottom: 4px;
}

.chat-message__tool-output {
    font-size: 12px;
    background-color: var(--gray-50);
    border-radius: 4px;
    padding: 8px;
    overflow-x: auto;
}

.chat-message__tool-output pre {
    margin: 0;
    background-color: transparent;
    padding: 0;
}

.chat-message__tool-output code {
    background-color: transparent;
    padding: 0;
    font-family: monospace;
    white-space: pre-wrap;
}

.chat-message__metadata {
    font-size: 12px;
    color: var(--gray-500);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.chat-message__help-required {
    margin-top: 16px;
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.chat-message__help-required-title {
    font-weight: 500;
    color: var(--gray-700);
    margin-bottom: 8px;
}

.chat-message__help-required-message {
    color: var(--gray-600);
    margin-bottom: 16px;
    font-size: 14px;
}

.chat-message__help-required-submit {
    padding: 8px 16px;
    background-color: var(--blue-600);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.chat-message__help-required-submit:hover:not(:disabled) {
    background-color: var(--blue-700);
}

.chat-message__help-required-submit:disabled {
    background-color: var(--gray-300);
    cursor: not-allowed;
}

.chat-message--empty {
  text-align: center;
  padding: 2rem;
  color: #666;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
}

.chat-message--empty .chat-message__content {
  font-size: 1rem;
  line-height: 1.5;
}

.chat-message__sparkle {
  font-size: 2rem;
  margin-bottom: 1rem;
  animation: sparkle 2s infinite;
}

@keyframes sparkle {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}