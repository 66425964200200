.case-users__title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}

.case-users__group-container {
  margin-bottom: 24px;
}

.case-users__group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-users__user-chip-container {
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  margin-bottom: 8px;
}

.case-users__group-title {
  color: var(--gray-500);
  font-weight: 500;
}

.case-users__group-info-banner {
  margin-top: 8px;
  width: fit-content;
}