.item-field-form__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-field-form__title {
  margin-right: 8px;
}


.item-field-form__button-container {
  display: flex;
}

.item-field-form__button-container > .button--secondary {
  margin-left: 16px;
}