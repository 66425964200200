.nav {
  background: white;
  height: 100%;
  padding: 24px 16px;
  position: relative;
  width: 72px;
  transition: width 0.3s ease-in-out;
  /* max-height: 1100px; */
  border-right: 1px solid var(--primary);
}

.nav--expanded {
  width: 220px;
}

.nav__company {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.nav__logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.nav__company-name {
  font-weight: bold;
}

.nav__toggle-button {
  position: absolute;
  top: 64px;
  right: -12px;
  background: var(--gray-100);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid var(--gray-300);
  cursor: pointer;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__toggle-button svg {
  width: 14px;
  height: 14px;
}

.nav__links {
  max-height: 1000px;
  height: calc(100% - 57px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.nav__links-top {
  flex: 1;
}

.nav__links-bottom {
  margin-top: auto;
}

.nav__link-button {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  width: 100%;
}

.nav__link-button--active {
  background: var(--blue-light);
  color: var(--blue);
  border-radius: 8px;
}

.nav__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}

.nav__user {
  margin-top: 16px;
  border-top: 1px solid var(--gray-300);
}