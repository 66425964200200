.drop-zone-container {
  margin-bottom: 15px;
}

.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border 0.3s, background-color 0.3s;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-zone:hover, .drop-zone:focus {
  border-color: #6c8eef;
  background-color: #f0f4ff;
}

.drop-zone p {
  color: #666;
  margin: 0;
  font-size: 14px;
}

.drop-zone-error {
  color: #d9534f;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
} 