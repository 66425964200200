.case-user-input {
  max-width: 500px;
  margin-top: 16px;
}

.case-user-input__buttons {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}