.search-result__container {
  padding: 16px 8px;
}

.search__highlight {
  background-color: #ff0;
  /* font-weight: bold; */
  /* padding: 2px; */
}

.search-result__link {
  font-size: 18px;
  font-weight: bold;
  color: var(--tertiary);
}

.search-result__data {
  padding: 8px;
  list-style-type: none;
  margin: 0;
}