.ai-extraction {
  margin: 20px 0;
  padding: 15px;
  border-radius: 8px;
}

.ai-extraction > h3 {
  margin-top: 0;
  margin-bottom: 0px;
}

.ai-extraction > p {
  margin-top: 0;
  margin-bottom: 15px;
}

.ai-extraction__meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extraction-actions {
  margin-top: 20px;
}

.extraction-error {
  margin-top: 15px;
  color: #d9534f;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
}

.extraction-results__actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
}

.extraction-results {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  background-color: white;
  display: flex;
  gap: 20px;
}

.extraction-results h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
}

.extraction-results pre {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  overflow: auto;
  max-height: 300px;
  font-size: 13px;
  white-space: pre-wrap;
  word-break: break-all;
} 

.extraction-results__extracted-data {
  flex: 1;
}

.extraction-results__current-data {
  flex: 1;
}

.extraction-results__extracted-data-item {
  display: flex;
  align-items: center;
  gap: 10px;
}