.case-step-completion {
  margin-top: 16px;
  flex: 1;
}

.case-step-complete-button {
  background-color: black;
  border-color: black;
  padding: 16px 32px;
  width: 100%;

  margin-top: 32px;
}

.case-step-complete-button:hover {
  color: black;
}

.case-step-completion__form-actions {
  display: flex;
  gap: 16px;
}

.case-step-completion__form-actions .button {
  flex: 1;
}

.case-step-completion {
  border: 1px solid var(--gray-300);
  padding: 16px;
  border-radius: 8px;
}

.case-step-completion__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.case-step-completion__error {
  color: var(--gray-500);
}
@media (min-width: 700px) {
  .case-step-completion__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}