.case-status {
  background-color: var(--gray-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 0;
  padding-right: 16px;
}

.case-status__completion-percentage {
  width: 80px;
  height: 80px;
  margin-left: 16px;
}

.case-status__completion-percentage-text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
