.configure-email-automation__container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.configure-email-automation__inputs {
  width: 60%;
}

.email-body-editor {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
}

.email-body-editor-content {
  min-height: 200px;
}