.info-banner {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  background-color: var(--blue-light);
  color: var(--blue);
}

.info-banner--warning {
  background-color: var(--yellow-light);
  color: var(--text);
}

.info-banner--success {
  background-color: var(--green-light);
  color: var(--green);
}

.info-banner svg {
  width: 20px;
  margin-right: 8px;
}

.info-banner--warning svg {
  color: var(--yellow);
}

.info-banner--success svg {
  color: var(--success);
}

.info-banner--info {
  background-color: var(--gray-100);
  color: var(--gray-500);
}