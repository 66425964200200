.edit-step__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-step__options {
  display: flex;
  flex-wrap: wrap;
}

.edit-step__require-process-items-here {
  margin-top: 32px;
}

.edit-step__require-process-items-here__text {
  font-size: 12px;
}