.case-documents {
  display: flex;
  gap: 48px;
}

.case-documents__list-container {
  flex: 1;
  max-width: 400px;
}

.case-documents__list-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.case-documents__upload-container {
  border: 1px solid var(--gray-100);
  padding: 16px;
  border-radius: 8px;
  flex: 1;
}

.case-documents__upload-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}