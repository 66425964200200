.letter-automation {
  padding: 16px;
  border: 1px solid var(--yellow);
  border-radius: 8px;
  margin-bottom: 32px;
  margin-top: 32px;
  min-height: 60px;
}

.letter-automation__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}