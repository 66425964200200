.guidance-note__container {
  padding: 16px;
  border-radius: 8px;
  background-color: var(--yellow-light);
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  /* max-width: 600px; */
  width: 100%;
}

.guidance-note__container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.guidance-note__container svg {
  width: 24px;
}

.guidance-note__title {
  margin: 0;
  margin-bottom: 4px; 
  font-size: 18px;
  font-weight: 600;
}

.guidance-note__text {
  margin: 0;
}

.guidance-note__text img {
  border-radius: 8px;
}

.guidance-note__text h2, .guidance-note__text ul {
  margin: 0;
  margin-bottom: 4px;
}
.guidance-note__text ul {
  padding-left: 16px;
}