.case-items__header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 15px;
}

.case-items__add-header {
  margin: 0;
  margin-bottom: 8px;
}
.case-items__data-container {
  max-height: 250px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.case-items__data-container::-webkit-scrollbar {
  display: none; /* WebKit */
}
