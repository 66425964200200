.case-items-list {
  /* border: 1px solid var(--gray-400); */
  min-height: 200px;
  /* border-radius: 8px; */
  padding: 8px;
  overflow-y: scroll;
  max-height: 400px;
}

.case-items-list__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-500);
  margin-top: 75px
}

.case-items-list__item {
  padding: 8px;
  border: 2px solid var(--gray-200);
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.case-items-list__item-display-field {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.case-items-list__item--selected {
  color: var(--primary);
  border-color: var(--primary);
}

.case-items-list__add-new {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: var(--primary);
  font-weight: 500;
  font-size: 12px;
}

.case-items-list__add-new:hover {
  text-decoration: underline;
}

.case-items-list__add-new svg {
  width: 16px;
  height: 16px;
}
