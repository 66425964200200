.audit-list__container {
  /* height: 100%; */
  overflow-y: scroll;
  padding: 8px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  max-height: 800px;
}

.audit-list__container::-webkit-scrollbar {
  display: none;
}

.audit-list__item {
  border: 1px solid var(--gray-200);
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
}

.audit-list__item__user {
  margin-bottom: 8px;
  border-bottom: 1px solid var(--gray-200);
  padding-bottom: 8px;
}


.audit-list__log {
  font-size: 14px;
  margin: 0;
  margin-bottom: 4px;
  font-weight: 500;
}

.audit-list__data-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: var(--gray-500);
  border-radius: 4px;
  padding: 4px;
}

.audit-list__data {
  font-size: 12px;
  display: block;
  padding: 2px;
}

.audit-list__meta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}

.audit-list__meta__date {
  margin: 0;
  font-size: 12px;
  color: var(--gray-500);
  display: flex;
  align-items: center;
  gap: 4px;
}

.audit-list__meta__icon {
  width: 16px;
  height: 16px;
}
