.analytics-page-container {
  display: flex;
  gap: 32px;
  min-height: 75vh;
}

.analytics-container {
  flex-grow: 1;
}

.analytics-stats-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  gap: 16px;
}

.analytics-chart-container {
  display: flex;
  gap: 16px;
}