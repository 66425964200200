.filter-checkbox-list {
  margin-bottom: 32px;
}

.filter-checkbox-list__values {
  padding-left: 12px;
}

.filter-checkbox-list-value {
  margin-bottom: 8px;
}

.filter-checkbox-list__no-values {
  color: var(--secondary-text);
  font-size: 14px;
}

.filter-checkbox-list__label {
  font-weight: 600;
  margin-bottom: 8px;
}

.filter-checkbox-list__no-values {
  display: block;
  margin-bottom: 8px;
  color: var(--gray-500);
}