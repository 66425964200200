.risk-management__content {
  display: flex;
  gap: 16px;
}

.risk-management__filters {
  max-width: 300px;
}

.risk-management__table {
  flex: 1;
  margin-right: 16px;
}

.risk-management-page__rag-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.risk-management-page__process-name {
  font-size: 12px;
  font-weight: 500;
  color: var(--gray-500);
}

.risk-management-page__mitigation-status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 16px;
  font-weight: 500;
  text-transform: capitalize;
  background: var(--gray-100);
  color: var(--gray-700);
  font-weight: 500;
}

.risk-management-page__mitigation-status--unmitigated {
  background: var(--red-light);
  color: var(--red);
}

.risk-management-page__mitigation-status--implemented {
  background: var(--grass-light);
  color: var(--success);
}

.risk-management-page__mitigation-status--awaiting_implementation {
  background: var(--yellow-light);
  color: var(--yellow-dark);
}

.risk-management-page__mitigation-date {
  padding-left: 4px;
  font-size: 12px;
  font-weight: 500;
  color: var(--gray-500);
}